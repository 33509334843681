const Settings = {
	// apiUrl: "https://patientportalapi.indici.ie/api/",
	apiUrl: 'https://nhldevconnect.indici.ie/api/'
	// apiUrl : 'https://devconnect.healthrecord.nz/api/'
};
export default Settings;

// apiUrl : 'https://stagingindiciconnect.itsmyhealth.nz/api/' https://erxindiciconnect.indici.nz/api/

// 'https://nhldevconnect.indici.ie/api/'
  